import React from "react";
import { GatsbyImage, IGatsbyImageData, Layout } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../../client-config";
import { ImageNode } from "gatsby-source-sanity/lib-es5/images/getGatsbyImageProps";

export interface AxptImageObject {
  asset: ImageNode;
  alt: string;
  width?: number;
  height?: number;
  fit: "clip" | "crop" | "fill" | "fillmax" | "max" | "scale" | "min";
}

const Image = (data: AxptImageObject) => {
  if (!data.asset) {
    return null;
  }

  const imageData = getGatsbyImageData(
    data.asset,
    { width: data.width, height: data.height, fit: data.fit },
    clientConfig.sanity
  );
  return <GatsbyImage image={imageData as IGatsbyImageData} alt={data.alt} />;
};

export default Image;
