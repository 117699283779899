import React from "react";
import { graphql, Link } from "gatsby";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import IconBox from "../components/icon-box/icon-box";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";
import Products from "../components/products/products";
import loadable from "@loadable/component";
import * as styles from "./danskebank.module.scss";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj, mapEdgesToNodes } from "../lib/helpers";
import Faq from "../components/faq/faq";
import Image from "../components/image/image";

const HubspotFormComponent = loadable(() => import("../components/hubspot-form/hubspot-form"));

export const query = graphql`
  query DanskebankPageQuery {
    site: sanityDanskebankPage {
      title
      _rawDescription
      seo
      keywords
      id
      mainImage {
        asset {
          _id
        }
        alt
      }
      danskebankLogo {
        asset {
          _id
        }
        alt
      }
    }
    faqs: allSanityFaqDanskebank {
      edges {
        node {
          _rawDescription
          slug {
            current
          }
          title
        }
      }
    }
  }
`;

const DanskebankPage = props => {
  const { data, errors } = props;

  const faqs = data && data.faqs && mapEdgesToNodes(data.faqs);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  const imageUrl = imageUrlFor(buildImageObj(site.mainImage))
    .width(600)
    .width(600)
    .fit("fill")
    .url();

  return (
    <Layout>
      <SEO title={site.title} description={site.seo} keywords={site.keywords} image={imageUrl} />
      <Container role="region">
        <div className={styles.heroContainer}>
          <div
            className={styles.logoImageContainer}
            data-sal="slide-up"
            data-sal-delay="350"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            {site && site?.mainImage && site?.mainImage?.asset && (
              <Image
                asset={site.mainImage.asset}
                width={300}
                alt={site.mainImage.alt}
                fit={"fill"}
              ></Image>
            )}
            {site && site?.danskebankLogo && site?.danskebankLogo?.asset && (
              <Image
                asset={site.danskebankLogo.asset}
                width={300}
                alt={site.danskebankLogo.alt}
                fit={"fill"}
              ></Image>
            )}
          </div>
          <div>{site._rawDescription && <BlockContent blocks={site._rawDescription || []} />}</div>
          <HubspotFormComponent
            portalId={5841642}
            formId="687bd83d-b123-4940-ab68-ec05cb514530"
            region="na1"
          ></HubspotFormComponent>
        </div>
      </Container>
      <Container backgroundColor="white" role="complementary">
        <Products></Products>
      </Container>
      <Container backgroundColor="white" role="complementary">
        <div className="icon-container">
          <IconBox
            width={90}
            height={90}
            imageUrl={"/get-in-touch.png"}
            title="Complete the form"
            description="Fill out the contact form below"
          ></IconBox>
          <IconBox
            width={90}
            height={90}
            imageUrl={"/Lets-talk.png"}
            title="Axeptia will contact you"
            description="Axeptia will contact you to set up Axeptia for a free and non-binding trial period of 30 days"
          ></IconBox>
          <IconBox
            width={90}
            height={90}
            imageUrl={"/demo.png"}
            title="Demo"
            description="When the setup is complete, Axeptia will provide a full demonstration of the solution."
          ></IconBox>
          <IconBox
            width={90}
            height={90}
            imageUrl={"/star.png"}
            title="Try it our yourself"
            description="Now you can test Axeptia with your own data. 30 days non-binding and free trial period."
          ></IconBox>
          <IconBox
            width={90}
            height={90}
            imageUrl={"/cloud.png"}
            title="Make a smart decision"
            description="After the trial period, you can easily decide whether you want to buy Axeptia or want to stop using Axeptia."
          ></IconBox>
        </div>
      </Container>

      <Container role="complementary">
        <Faq faqs={faqs}></Faq>
      </Container>
    </Layout>
  );
};

export default DanskebankPage;
