import React from "react";

import * as styles from "./icon-box.module.scss";

type IconBoxProps = {
  imageUrl: string;
  title: string;
  description?: string;
  width?: number;
  height?: number;
};

const IconBox = ({ imageUrl, title, description, width = 150, height = 150 }: IconBoxProps) => {
  return (
    <div
      className={styles.container}
      data-sal="slide-up"
      data-sal-delay="50"
      data-sal-easing="ease"
      data-sal-duration="500"
    >
      <img width={width} height={height} src={imageUrl} alt={"Illustrasjonsbilde " + title} />
      {title && <h3>{title}</h3>}
      {description && <span>{description}</span>}
    </div>
  );
};

export default IconBox;
