import React from "react";

import * as styles from "./products.module.scss";
import { buildImageObj, mapEdgesToNodes } from "../../lib/helpers";
import { imageUrlFor, SimpleImageModel } from "../../lib/image-url";
import { graphql, Link, StaticQuery } from "gatsby";
import BlockContent from "../block-content";
import classNames from "classnames/bind";

let cx = classNames.bind(styles);

export interface ProductModel {
  id: string;
  mainImage: SimpleImageModel;
  title: string;
  buttonText: string;
  buttonUrl: string;
  saleText: string;
  content: {
    _rawText: any;
    alignment: string;
    _rawContentFooterText: any;
    _rawContentHeaderText: any;
  };
}

const Products = () => (
  <StaticQuery
    query={graphql`
      query ProductsQuery {
        products: allSanityProduct {
          edges {
            node {
              _id
              mainImage {
                asset {
                  _id
                }
                alt
              }
              title
              buttonText
              buttonUrl
              saleText
              content {
                _rawText
                alignment
                _rawContentFooterText
                _rawContentHeaderText
              }
            }
          }
        }
      }
    `}
    render={data => {
      const productsNodes = data && data.products && mapEdgesToNodes(data.products);
      const products: ProductModel[] = productsNodes;

      let classNameContent = cx({
        onlyDesktop: true
      });

      if (products) {
        return (
          <ul className={styles.container}>
            {products.map((product, i) => (
              <li key={`product-${i}`} className={styles.product}>
                <div className={styles.imageContainer}>
                  {product.saleText && (
                    <span
                      style={{ backgroundColor: i === 1 ? "#119097" : null }}
                      className={styles.badge}
                    >
                      {product.saleText}
                    </span>
                  )}
                  {product.mainImage && product.mainImage.asset && (
                    <img
                      width={1081}
                      height={668}
                      src={imageUrlFor(buildImageObj(product.mainImage))
                        .width(1081)
                        .height(668)
                        .url()}
                      alt={product.mainImage.alt}
                    />
                  )}
                </div>
                <div className={classNames(product.content.alignment, styles.content)}>
                  <div className={styles.contentHeader}>
                    {product.content && (
                      <BlockContent blocks={product.content._rawContentHeaderText || []} />
                    )}
                  </div>
                  <div className={styles.list}>
                    {product.content && <BlockContent blocks={product.content._rawText || []} />}
                  </div>
                  <div className={styles.contentFooter}>
                    <BlockContent blocks={product.content._rawContentFooterText || []} />
                    {product.buttonText && (
                      <Link
                        style={{ marginTop: "2rem" }}
                        to={product.buttonUrl}
                        className="primary-link-btn"
                      >
                        {product.buttonText}
                      </Link>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        );
      } else {
        return null;
      }
    }}
  />
);

export default Products;
