import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as styles from "./faq.module.scss";
import BlockContent from "../block-content";

const Faq = props => {
  const faqs = props.faqs;
  if (faqs?.length) {
    return (
      <div className={styles.wrapper}>
        <h3>FAQ</h3>
        {faqs.map((faq, i) => (
          <Accordion key={`faq-${i}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1a-content-${i}`}
              id={`panel1a-header-${i}`}
            >
              <Typography>{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {faq._rawDescription && <BlockContent blocks={faq._rawDescription || []} />}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  } else {
    return null;
  }
};

export default Faq;
